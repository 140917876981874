// FieldFileInput.js

import React, { useState, useEffect } from 'react';
import styles from './FieldFileInput.module.css';

const FieldFileInput = ({ input, meta, initialValue, ...props }) => {
  const [fileList, setFileList] = useState(Array.isArray(input.value) ? input.value : []);
  const maxFiles = 3; // Maximum number of files
  const maxSize = 5 * 1024 * 1024; // Maximum file size (5 MB)

  useEffect(() => {
    console.log(initialValue, 'initialValue');
  }, [initialValue]);

  useEffect(() => {
    console.log(fileList, 'fileList');
  }, [fileList]);

  // Synchronize local state with input.value when it changes
  useEffect(() => {
    setFileList(input.value || []);
  }, [input.value]);

  const handleFileChange = event => {
    const files = Array.from(event.target.files);

    if (fileList.length + files.length > maxFiles) {
      alert(`You can only upload up to ${maxFiles} files.`);
      return;
    }

    const validFiles = files.filter(file => {
      if (file.size > maxSize) {
        alert(
          `File ${file.name} exceeds the maximum size of ${(maxSize / 1024 / 1024).toFixed(
            2
          )} MB.`
        );
        return false;
      }
      return true;
    });

    const newFileObjects = validFiles.map(file => ({
      file,
      isNew: true,
    }));
  
    const updatedFileList = [...fileList, ...newFileObjects];
    setFileList(updatedFileList);
    input.onChange(updatedFileList);
  };

  const handleRemoveFile = index => {
    const updatedFileList = fileList.filter((_, i) => i !== index);
    setFileList(updatedFileList);
    input.onChange(updatedFileList);
  };

  return (
    <div>
      <label className={styles.label}>Upload Files</label>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        className={styles.fileInput}
        id="file-upload"
      />
      <label htmlFor="file-upload" className={styles.uploadBox}>
        <span className={styles.plusIcon}>+</span>
      </label>

      <div className={styles.fileList}>
        {fileList.map((fileItem, index) => {
          const isNewFile = fileItem.isNew;
          const fileName = isNewFile ? fileItem.file.name : fileItem.fileName; // Adjust according to your existing file object structure

          return (
            <div key={index} className={styles.fileItem}>
              <div className={styles.fileIcon}>📄</div>
              <span className={styles.fileName}>{fileName}</span>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveFile(index)}
                >
                  Remove
                </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FieldFileInput;






